export const urlJoin = (base: string | URL, ...args: string[]) => {
  try {
    let result: URL = new URL(base);
    for (const arg of args) {
      result.pathname += `/${arg}`;
    }
    return result;
  } catch (e) {
    throw new Error(`Failed to construct URL from '${base}'`);
  }
};
