import {
  Backdrop,
  Button,
  Card,
  Table,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { DEV_FLAG, DEV_FLAGS } from "@features/dev-flags/types";
import { useHotkeys } from "react-hotkeys-hook";
import { KEYBOARD_NAMED_BINDS } from "@/features/keyboard-hotkeys";
import { useCommandPaletteRegistration } from "@/features/command-palette";

type ConfigureDevFlagsProps = {};

function DevFlag({ flag }: { flag: DEV_FLAG }) {
  const [value, setValue] = useState<string | null>(
    DEV_FLAGS.GET_DEVELOPER_FLAG(flag),
  );

  useEffect(() => {
    DEV_FLAGS.APPLY_DEVELOPER_FLAG(flag, value);
  }, [value]);

  return (
    <TableRow>
      <TableCell>{flag}</TableCell>
      <TableCell>
        <TextField
          value={value}
          onChange={(ev) => {
            const value = ev.target.value.length > 0 ? ev.target.value : null;
            setValue(value);
          }}
        />
      </TableCell>
    </TableRow>
  );
}

export function DevFlagConfigurator(props: ConfigureDevFlagsProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [fieldToAdd, setFieldToAdd] = useState<string>("");

  useHotkeys(KEYBOARD_NAMED_BINDS.DEV.TOGGLE_FLAG_EDITOR, () =>
    setIsOpen((x) => !x),
  );
  useHotkeys(KEYBOARD_NAMED_BINDS.GENERAL.CLOSE_DIALOG, () => setIsOpen(false));

  useCommandPaletteRegistration({
    id: "open-dev-flag-configurator",
    name: "Open development flag configurator",
    description: "Opens the development flag configurator view",
    callback: () => setIsOpen(true),
  });

  return (
    <Backdrop
      open={isOpen}
      sx={{
        zIndex: 999,
      }}
    >
      <Card>
        <Table>
          {DEV_FLAGS.GET_ALL_ACTIVE_FLAG_KEYS().map((x) => (
            <DevFlag flag={x} />
          ))}
          <TableRow>
            <TableCell>Add a flag</TableCell>
            <TableCell>
              <TextField
                value={fieldToAdd}
                onChange={(ev) => setFieldToAdd(ev.target.value)}
              />
              <Button
                onClick={() => {
                  DEV_FLAGS.APPLY_DEVELOPER_FLAG(fieldToAdd, "true", "session");
                  setFieldToAdd("");
                }}
              >
                Add
              </Button>
            </TableCell>
          </TableRow>
        </Table>
      </Card>
    </Backdrop>
  );
}
