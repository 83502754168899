import ReactDOM from "react-dom/client";
import { App } from "./app";
import "./index.css";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import reportWebVitals from "./reportWebVitals";
import en from "javascript-time-ago/locale/en";
import TimeAgo from "javascript-time-ago";

(window as any).Apex = {
  chart: {
    foreColor: "#ccc",
    toolbar: {
      show: true,
    },
  },
  stroke: {
    width: 3,
  },
  dataLabels: {
    enabled: false,
  },
  tooltip: {
    theme: 'dark',
    // custom: function ({ seriesIndex, dataPointIndex, w }: {seriesIndex: any, dataPointIndex: any, w: any}) {
    //   const open = w.globals.seriesCandleO[seriesIndex][dataPointIndex]
    //   const high = w.globals.seriesCandleH[seriesIndex][dataPointIndex]
    //   const low = w.globals.seriesCandleL[seriesIndex][dataPointIndex]
    //   const close = w.globals.seriesCandleC[seriesIndex][dataPointIndex]
    //   return (
    //     '<div class="apexcharts-tooltip-candlestick">' +
    //     '<div>Open: <span class="value">' +
    //     open +
    //     '</span></div>' +
    //     '<div>High: <span class="value">' +
    //     high +
    //     '</span></div>' +
    //     '<div>Low: <span class="value">' +
    //     low +
    //     '</span></div>' +
    //     '<div>Close: <span class="value">' +
    //     close +
    //     '</span></div>' +
    //     '</div>'
    //   )
    // }
  },
  grid: {
    borderColor: "#535A6C",
    xaxis: {
      lines: {
        show: false,
      },
    },
  },
};

TimeAgo.addDefaultLocale(en);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
