import { urlJoin } from "../../workarounds/url-join";
import { MicroserviceInstance } from "../../types/minimumServiceStructure";

export async function serviceDiscoveryMicroservicePlugin() {
  const rootURI = await (async () => {
    if (typeof globalThis.window === 'undefined') {
      // NodeJS
      const value = process.env["SERVICE_DISCOVERY_ROOT_URI"];
      if (value === undefined) {
        throw new Error(`Unable to resolve service discovery service root URI: environment is NodeJS, but environment variable 'SERVICE_DISCOVERY_ROOT_URI' is undefined`);
      }
      return value;
    }
    else {
      // Browser

      const value = process.env["REACT_APP_SERVICE_DISCOVERY_ROOT_URI"];
      if (value === undefined) {
        throw new Error(`Unable to resolve service discovery service root URI: environment is React, but environment variable 'REACT_APP_SERVICE_DISCOVERY_ROOT_URI' is undefined. Rebuild the application.`);
      }
      return `${window.location.protocol}//${value}`;
    }
  })();
  const environment = await (async () => {
    if (typeof globalThis.window === 'undefined') {
      // NodeJS
      const value = process.env["SERVICE_DISCOVERY_ENVIRONMENT"];
      if (value === undefined) {
        throw new Error(`Unable to resolve service discovery environment: environment is NodeJS, but environment variable 'SERVICE_DISCOVERY_ENVIRONMENT' is undefined`);
      }
      return value;
    }
    else {
      // Browser
      const value = process.env["REACT_APP_SERVICE_DISCOVERY_ENVIRONMENT"];
      if (value === undefined) {
        throw new Error(`Unable to resolve service discovery environment: environment is React, but environment variable 'REACT_APP_SERVICE_DISCOVERY_ENVIRONMENT' is undefined. Rebuild the application.`);
      }
      return value;
    }
  })();

  let availableMicroservicesCache: MicroserviceInstance[] | undefined = undefined;

  const getAvailableServices: () => Promise<
    MicroserviceInstance[]
  > = async () => {
    if (availableMicroservicesCache !== undefined) {
      return availableMicroservicesCache;
    }
    const fullURI = new URL(urlJoin(rootURI));
    fullURI.searchParams.append("env", environment);

    try {
      const response = await fetch(fullURI);
      if (!response.ok) {
        throw new Error(`Failed to fetch service instances`);
      }
      const responseBody: MicroserviceInstance[] = await response.json();
      availableMicroservicesCache = responseBody;
      setTimeout(() => {
        availableMicroservicesCache = undefined;
      }, 120 * 1000);
      return responseBody;
    } catch (e) {
      throw new Error(`Failed to fetch microservices from ${fullURI}`);
    }
  };

  const getAvailableFeatures: () => Promise<string[]> = async () => {
    const services = await getAvailableServices();
    return services.flatMap(x => x.fulfills).filter((val, index, arr) => index === arr.findIndex(x => x === val));
  };

  const getAvailableServicesForCapability: (
    capabilities: string[],
  ) => Promise<MicroserviceInstance[]> | MicroserviceInstance[] = async (
    capabilities,
  ) => {
      const allServices = await getAvailableServices();
      return allServices.filter(candidate => capabilities.every(requiredCapability => candidate.fulfills.some(candidateCapability => requiredCapability === candidateCapability)));
    };

  const getAvailableServiceInstanceForCapability: (
    capabilities: string[],
  ) => Promise<MicroserviceInstance> | MicroserviceInstance = async (
    capabilities,
  ) => {
      const allServices = await getAvailableServices();
      const possibilities = allServices.filter((x) =>
        capabilities.every((required) => x.fulfills.some((x) => x === required)),
      );
      if (possibilities.length < 1) {
        throw new Error(
          `Service discovery failed to locate any services capable of fulfilling capabilities '${JSON.stringify(capabilities)}'`,
        );
      }
      return possibilities[0];
    };

  return {
    getAvailableFeatures,
    getAvailableServices,
    getAvailableServicesForCapability,
    getAvailableServiceInstanceForCapability,
    underlying: {
      rootURI: rootURI,
      environment: environment
    }
  };
}
