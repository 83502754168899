import React, { ReactNode } from "react";
import SvgIcon, { SvgIconTypeMap } from "@mui/material/SvgIcon";
import styles from "./BorderedBox.module.scss";
import { OverridableComponent } from "@mui/material/OverridableComponent";

export function BorderedSection({
  icon,
  title,
  children,
}: {
  icon?: ReactNode;
  title?: string | ReactNode;
  children: React.ReactNode;
}) {
  const Icon = icon;
  return (
    <div className={styles.mainContainer}>
      <div className={styles.header}>
        <div className={styles.headerBorderBefore}></div>
        {(icon || title) && (
          <div className={styles.headerTitle}>
            {icon && Icon}
            {title && <span className={styles.title}>{title}</span>}
          </div>
        )}
        <div className={styles.headerBorderAfter}></div>
      </div>
      <div className={styles.childrenContainer}>{children}</div>
    </div>
  );
}
