import { getUniqueListBy } from "@/logic/helpers/getUniqueList";
import { toast } from "react-hot-toast";

export type DEV_FLAG =
  | "SIMULATE_DELAY"
  | "DEBUG_PROMISE_HOOKS"
  | "TREE_VIEW_STATE_INSPECTION_MODE"
  | string;

export const DEV_FLAGS = {
  APPLY_DEVELOPER_FLAG: (
    flag: DEV_FLAG,
    value: string | null,
    duration: "session" | "indefinite" = "session",
  ) => {
    const STORAGE_MEDIUM =
      duration === "session" ? window.sessionStorage : window.localStorage;
    if (value !== null) {
      STORAGE_MEDIUM.setItem(flag, value);
    } else {
      STORAGE_MEDIUM.removeItem(flag);
    }
  },
  GET_DEVELOPER_FLAG: (flag: DEV_FLAG) => {
    const mediums = [window.sessionStorage, window.localStorage];
    for (const medium of mediums) {
      const result = medium.getItem(flag);
      if (result !== null) {
        return result;
      }
    }
    return null;
  },
  REMOVE_DEVELOPER_FLAG: (flag: DEV_FLAG) => {
    const mediums = [window.sessionStorage, window.localStorage];
    for (const medium of mediums) {
      medium.removeItem(flag);
    }
  },
  GET_ALL_ACTIVE_FLAG_KEYS: () => {
    let result: string[] = [];
    const providers = [sessionStorage, localStorage];
    for (const provider of providers) {
      let index = 0;
      let currentKey: string | null;
      while ((currentKey = provider.key(index++)) !== null) {
        result.push(currentKey!);
      }
    }
    return getUniqueListBy(result, (x) => x);
  },
};
