import { Mutex } from "async-mutex"

// Creates a system for 'single-threaded' value access.
// Used for deduplicating requests by forcing sequential operations on a per-key basis.
export function createKeyedMutexAccessor<TKeys extends string, TValues>(target: {
  [key in TKeys]: TValues
}, defaultGenerator: (key: TKeys) => TValues | Promise<TValues>) {
  const mutexes: {
    [key: string]: WeakRef<Mutex>
  } = {};

  const getOrCreate = (key: string) => {
    let target = mutexes[key]?.deref();
    if (target === undefined) {
      target = new Mutex();
      mutexes[key] = new WeakRef(target);
    }
    return target!;
  };

  const operateOn = async <TResult, TKey extends TKeys>(key: TKey, action: (context: { value: TValues }) => TResult | Promise<TResult>) => {
    const mutex = getOrCreate(key);
    return await mutex.runExclusive(async () => {
      if (target[key] === undefined) {
        target[key] = await defaultGenerator(key);
      }
      const context = {
        value: target[key]
      };
      const result = await action(context);
      // Synchronize the value back to the store in case it was changed
      target[key] = context.value;
      return result;
    });
  };

  return { operateOn };
}
