import { getUniqueListBy } from "@/logic/helpers/getUniqueList";
import {
  Backdrop,
  Card,
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import { ObjectInspector } from "react-inspector";
import { useEffect, useId, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { useHotkeys } from "react-hotkeys-hook";
import { create } from "zustand";
import { KEYBOARD_NAMED_BINDS } from "../keyboard-hotkeys";

export type CommandPaletteInstance = {
  id: string;
  name: string;
  description?: string;
  icon?: React.ReactNode;
  eventCallback?: (id: "mouse-enter" | "mouse-exit") => void;
  callback: () => void | Promise<void>;
};

const useCommandPaletteStore = create<{
  instances: CommandPaletteInstance[];
  addInstance: (incoming: CommandPaletteInstance) => void;
  removeInstance: (id: string) => void;
}>((set) => ({
  instances: [],
  addInstance: (incoming) =>
    set((state) => ({
      instances: [...state.instances, incoming],
    })),
  removeInstance: (id) =>
    set((state) => ({
      instances: state.instances.filter((x) => x.id !== id),
    })),
}));

export function useCommandPaletteRegistration(item: CommandPaletteInstance) {
  const store = useCommandPaletteStore();

  useEffect(() => {
    store.addInstance(item);

    return () => {
      store.removeInstance(item.id);
    };
  }, []);
}

export function manuallyRegisterCommandPaletteItem(
  item: CommandPaletteInstance,
) {
  const state = useCommandPaletteStore.getState();
  state.addInstance(item);
}

export function manuallyUnregisterCommandPaletteItem(id: string) {
  const state = useCommandPaletteStore.getState();
  state.removeInstance(id);
}

export function useCommandPaletteToggledBoolean(
  item: Omit<CommandPaletteInstance, "callback" | "eventCallback" | "id"> & {
    id?: string;
  },
  defVal: boolean = false,
) {
  const [value, setValue] = useState(defVal);
  const [hover, setHover] = useState(false);

  const id = useId() + Math.random().toFixed(20);

  useCommandPaletteRegistration({
    ...item,
    id: (item.id ?? "") + id,
    callback: () => setValue((x) => !x),
    eventCallback: (id) => {
      if (id === "mouse-enter") {
        setHover(true);
      } else if (id === "mouse-exit") {
        setHover(false);
      }
    },
  });

  return [value || hover, setValue];
}

export function CommandPaletteHost() {
  const store = useCommandPaletteStore();
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState<string>("");
  const [activePromiseCommand, setActivePromiseCommand] =
    useState<CommandPaletteInstance | null>(null);
  useHotkeys(KEYBOARD_NAMED_BINDS.DEV.TOGGLE_COMMAND_PALETTE, () =>
    setIsOpen((x) => !x),
  );
  useHotkeys(
    KEYBOARD_NAMED_BINDS.GENERAL.CLOSE_DIALOG,
    () => isOpen && setIsOpen(false),
  );

  const invokeCallback = (target: CommandPaletteInstance) => {
    const result = target.callback();
    if (result instanceof Promise) {
      setActivePromiseCommand(target);
      result.finally(() => setActivePromiseCommand(null));
    }
    setIsOpen(false);
  };

  const invokeEvent = (
    id: "mouse-enter" | "mouse-exit",
    on: CommandPaletteInstance,
  ) => {
    on.eventCallback?.(id);
  };

  const [filteredEntries, setFilteredEntries] = useState<
    CommandPaletteInstance[]
  >([]);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isOpen) {
      inputRef.current?.focus();
    } else {
      setInputValue("");
    }
  }, [isOpen]);

  useEffect(() => {
    if (inputValue.length === 0) {
      setFilteredEntries(store.instances);
    }
    let newList: CommandPaletteInstance[] = [];
    let normalizedInputValue = inputValue.toLowerCase().replaceAll(" ", "");
    newList.push(
      ...store.instances.filter((potential) =>
        potential.name
          .toLowerCase()
          .replaceAll(" ", "")
          .includes(normalizedInputValue),
      ),
    );
    newList.push(
      ...store.instances.filter((potential) =>
        potential.description
          ?.toLowerCase()
          .replaceAll(" ", "")
          .includes(normalizedInputValue),
      ),
    );

    newList = getUniqueListBy(newList, (x) => x.id);

    setFilteredEntries(newList);
  }, [inputValue, store.instances]);

  const tryInvokeDefaultCommand = () => {
    if (filteredEntries.length > 0) {
      invokeCallback(filteredEntries[0]);
    }
  };

  const handleKeyPress = (ev: React.KeyboardEvent<HTMLDivElement>) => {
    if (ev.key === "Enter") {
      tryInvokeDefaultCommand();
    } else if (ev.key === "Escape") {
      isOpen && setIsOpen(false);
    }
  };

  return (
    <>
      <Backdrop
        open={activePromiseCommand !== null}
        sx={{
          zIndex: 999999,
        }}
      >
        <Card sx={{ textAlign: "center", padding: "15px" }}>
          <CircularProgress />
          <Typography variant="body1">
            {`Running '${activePromiseCommand?.id ?? "none"}'`}
          </Typography>
        </Card>
      </Backdrop>
      <Backdrop
        open={isOpen}
        sx={{
          zIndex: 9999,
        }}
      >
        <Card>
          <ObjectInspector data={store} />
          <TextField
            sx={{
              width: "100%",
              maxHeight: "75%",
            }}
            inputRef={inputRef}
            autoFocus={true}
            value={inputValue}
            onKeyDown={handleKeyPress}
            onChange={(ev) => setInputValue(ev.target.value)}
          />
          <List>
            {filteredEntries.map((entry) => (
              <ListItemButton
                key={entry.id}
                onClick={() => {
                  invokeCallback(entry);
                  setIsOpen(false);
                }}
                onMouseEnter={() => invokeEvent("mouse-enter", entry)}
                onMouseLeave={() => invokeEvent("mouse-exit", entry)}
              >
                <ListItemIcon>{entry.icon as any}</ListItemIcon>
                <ListItemText
                  primary={entry.name}
                  secondary={entry.description}
                />
              </ListItemButton>
            ))}
          </List>
        </Card>
      </Backdrop>
    </>
  );
}
