import { SensorStatusChunkAutoloader } from "@/components/data/SensorStatus";
import { Themer } from "@/components/theme/Themer";
import { CommandPaletteHost } from "@/features/command-palette";
import { useCooperativeGraphingFormatters } from "@/features/CooperativeGraphing/InjectorComponent";
import { DevFlagConfigurator } from "@/features/dev-flags/components/ConfigureDevFlags";
import {
  AvailableDataSourcesContextProvider,
  AvailableSensorsContextProvider,
  GraphPackagesContextProvider,
  MapTilesetsContextProvider,
  SelectedDateRangeStoreContextProvider,
  SelectedSensorsContextProvider,
  SensorStatusChunksContextProvider,
} from "@/providers";
import { Toaster } from "react-hot-toast";

export function AppProvider({ children }: { children: React.ReactNode }) {
  useCooperativeGraphingFormatters();
  return (
    <>
      <Themer>
        <AvailableSensorsContextProvider>
          <SelectedSensorsContextProvider>
            <AvailableDataSourcesContextProvider>
              <SelectedDateRangeStoreContextProvider>
                <GraphPackagesContextProvider>
                  <MapTilesetsContextProvider>
                    <SensorStatusChunksContextProvider>
                      <SensorStatusChunkAutoloader />
                      <Toaster />
                      <CommandPaletteHost />
                      <DevFlagConfigurator />
                      {children}
                    </SensorStatusChunksContextProvider>
                  </MapTilesetsContextProvider>
                </GraphPackagesContextProvider>
              </SelectedDateRangeStoreContextProvider>
            </AvailableDataSourcesContextProvider>
          </SelectedSensorsContextProvider>
        </AvailableSensorsContextProvider>
      </Themer>
    </>
  );
}
