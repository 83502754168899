import { urlJoin } from "../../workarounds/url-join";
import { getMicroserviceCoreAPIClient } from "..";
import { MapArea } from "../../types/geodata/MapArea";
import { ClientTileset, Tileset } from "../../types/geodata/Tileset";
import { MSCCommon } from "../../impl/shared";
const GEODATA_SPECIFIC_CONSTANTS = {
  CAPABILITY: "geodata",
};

export async function geoDataMicroservicePlugin() {
  const getServiceInstance = async () => {
    const microserviceAPIClient = await getMicroserviceCoreAPIClient();
    const services =
      await microserviceAPIClient.serviceDiscovery.getAvailableServicesForCapability(
        [GEODATA_SPECIFIC_CONSTANTS.CAPABILITY],
      );
    if (services.length < 1) {
      throw new Error(
        `Service discovery found 0 services capable of handling capability '${GEODATA_SPECIFIC_CONSTANTS.CAPABILITY}'`,
      );
    }
    return services[0];
  };

  const isAvailable = async () => {
    const microserviceAPIClient = await getMicroserviceCoreAPIClient();
    const services =
      await microserviceAPIClient.serviceDiscovery.getAvailableServicesForCapability(
        [GEODATA_SPECIFIC_CONSTANTS.CAPABILITY],
      );
    return services.length > 0;
  };

  const getTilesetsRaw: () => Promise<Tileset[]> = async () => {
    const serviceDetails = await getServiceInstance();
    const tilesetIDs = await (async () => {
      const requestURI = urlJoin(serviceDetails.rootURI, "tilesets");
      const result = await fetch(requestURI);
      const tilesetIDs: string = await MSCCommon.nestJS.getResponseOrThrowError(result);
      return tilesetIDs;
    })();
    let results: Tileset[] = [];
    for (const id of tilesetIDs) {
      const requestURI = urlJoin(serviceDetails.rootURI, "tileset", id);
      const response = await fetch(requestURI);
      const responseBody = await MSCCommon.nestJS.getResponseOrThrowError(response);
      results.push(responseBody);
    }
    return results;
  };

  const getTilesets: () => Promise<ClientTileset[]> = async () => {
    const rawTilesets = await getTilesetsRaw();
    // return rawTilesets.map(x => ({
    //   ...x,
    //   currentOpacity: 1,
    //   isActive: x.activeByDefault,
    //   leafletURL: await createLeafletURLFromTileset(x)
    // }));
    const results: ClientTileset[] = [];
    for (const x of rawTilesets) {
      results.push({
        ...x,
        currentOpacity: 1,
        isActive: x.activeByDefault,
        leafletURL: await createLeafletURLFromTileset(x),
        currentZIndex: x.defaultZIndex
      });
    }
    return results;
  };

  const createLeafletURLFromTileset = async (tileset: Tileset) => {
    const serviceDetails = await getServiceInstance();
    return `${serviceDetails.rootURI}/tiles/${tileset.path}/{z}/{x}/{y}.png`;
  }

  const getMapAreas: () => Promise<MapArea[]> = async () => {
    const serviceDetails = await getServiceInstance();
    const requestURI = urlJoin(serviceDetails.rootURI, "areas");
    const response = await fetch(requestURI);
    return await MSCCommon.nestJS.getResponseOrThrowError(response);
  };

  return {
    isAvailable,
    getTilesets,
    getMapAreas,
    createLeafletURLFromTileset
  };
}
