import { DEV_FLAGS } from "@/features/dev-flags/types";
import { useMemo } from "react";
import { RouterProvider } from "react-router-dom";
import { AppProvider } from "./provider";
import { createRouter } from "./routes";

type AppProps = {};

const AppRouter = () => {
  const router = useMemo(() => {
    return createRouter({});
  }, []);

  return <RouterProvider router={router} />;
};

export function App(props: AppProps) {
  return (
    <AppProvider>
      <AppRouter />
    </AppProvider>
  );
}
