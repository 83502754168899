import { getMicroserviceCoreAPIClient } from "@/features/microservice-core";
import { useSensorStatusChunksContextStore } from "@/providers";
import { useMemo } from "react";
import { useInterval } from "usehooks-ts";

export function SensorStatusChunkAutoloader() {
  const statusStore = useSensorStatusChunksContextStore();
  const update = useMemo(() => {
    return async () => {
      const client = await getMicroserviceCoreAPIClient();
      const result = await client.sensorData.getAllSensorStatusChunks();
      statusStore.modify(() => result);
    };
  }, [statusStore]);

  useMemo(() => {
    update();
  }, []);

  useInterval(update, 15 * 1000);
  return (<></>);
}
