import { ReactElement } from "react";
import { createBrowserRouter, RouteObject } from "react-router-dom";
import { SensorDataTesting } from "../pages/SensorData/SensorDataTesting";
import NewspaperIcon from '@mui/icons-material/Newspaper';
import StorageIcon from '@mui/icons-material/Storage';

export type CreateAppRouterProps = {};

export type Page = RouteObject & {
  name: string,
  description: string,
  icon: ReactElement,
  hidden?: boolean,
  disabled?: boolean,
  requiredFeatures: string[]
};

export function getApplicationPages(): Page[] {
  return [
    {
      name: 'Landing Page',
      description: "The landing page",
      icon: <NewspaperIcon />,
      hidden: true,
      requiredFeatures: [],
      path: "/",
      lazy: async () => {
        const { LandingPage } = await import("@/app/pages/LandingPage");
        return { Component: LandingPage };
      },
    },
    {
      name: 'Login Page',
      description: "The login page",
      icon: <NewspaperIcon />,
      hidden: true,
      requiredFeatures: ['authentication'],
      path: '/login',
      lazy: async () => {
        const { LoginPage } = await import("@/app/pages/LoginPage");
        return { Component: LoginPage }
      }
    },
    {
      name: 'Sensor Data Graphing',
      description: "Create rich, complex sensor data graphs",
      icon: <NewspaperIcon />,
      requiredFeatures: ['sensor-data'],

      path: "/sensorDataGraphing",
      lazy: async () => {
        const { SensorDataGraphingPage } = await import(
          "@/app/pages/SensorData"
        );
        return { Component: SensorDataGraphingPage };
      },
    },
    {
      name: 'Map Area Details',
      description: "View sensor information",
      icon: <NewspaperIcon />,
      requiredFeatures: ['sensor-data', 'geodata'],
      path: "/mapAreaDetails",
      lazy: async () => {
        const { MapAreaDetailsPage } = await import("@/app/pages/MapAreaDetailsPage");
        return { Component: MapAreaDetailsPage }
      }
    },
    {
      name: 'Metadata Editor',
      description: "Edit sensor collections & instances",
      icon: <StorageIcon />,
      path: '/metadata',
      lazy: async () => {
        const { MetadataEditorPage } = await import('@/app/pages/MetadataEditor');
        return { Component: MetadataEditorPage };
      },
      requiredFeatures: ['sensor-data'] // TODO: ChangeME
    },
    {
      name: 'Testing Page',
      description: "Testing page",
      icon: <StorageIcon />,
      path: '/testing',
      lazy: async () => {
        const { TestingPage } = await import('@/app/pages/Testing');
        return { Component: TestingPage };
      },
      requiredFeatures: []
    },
    {
      name: 'TreeView Test',
      description: 'TreeView test',
      icon: <NewspaperIcon />,
      requiredFeatures: [],
      path: "/dev/testing/components/treeview",
      hidden: true,
      lazy: async () => {
        const { StyledTreeView } = await import("../../components/ui/treeview");
        return { Component: StyledTreeView };
      },
    },
    {
      name: '404 Page',
      description: '404 page',
      hidden: true,
      requiredFeatures: [],
      icon: <NewspaperIcon />,
      path: "*",
      element: <SensorDataTesting />,
    },
  ]
}

export const createRouter = (_: CreateAppRouterProps) => createBrowserRouter(getApplicationPages());
