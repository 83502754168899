import { create } from "zustand";

export const KEYBOARD_NAMED_BINDS = {
  DEV: {
    TOGGLE_FLAG_EDITOR: "space+d+f",
    TOGGLE_COMMAND_PALETTE: "control+escape",
  },
  GENERAL: {
    CLOSE_DIALOG: "escape",
  },
};

type BindInstance = {
  bind: string;
  callbacks: ((bind: string) => void)[][];
};

type BindRegistrationData = {
  bind: string;
  callback: (bind: string) => void;
  registrationMode: "shared" | "owned";
};

type BindRegistrationResult = {
  unbind: () => void;
};
