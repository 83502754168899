import { usePromise } from "../../../../hooks/promiseHook";
import { useMemo } from "react";
import { getMicroserviceCoreAPIClient } from "../../api";
import {
  Box,
  Button,
  ButtonProps,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Link,
  Skeleton,
  Typography,
} from "@mui/material";
import { Tooltip } from "@mui/material";
import {
  Info,
  Person,
  Search,
  Warning,
  WarningAmber,
} from "@mui/icons-material";
import { LoadingButton, LoadingButtonProps, Masonry } from "@mui/lab";
import React from "react";
import { MicroserviceInstance } from "../../types/minimumServiceStructure";
import { BorderedSection } from "../../../../components/BorderedBox/Index";

export function RequiresBackendFeaturesButton(
  props: {
    features: string[];
  } & LoadingButtonProps,
) {
  const [isAvailable, setIsAvailable] = usePromise<boolean>();

  useMemo(() => {
    setIsAvailable(
      (async () => {
        const api = await getMicroserviceCoreAPIClient();
        await new Promise((resolve) => setTimeout(resolve, 750));
        return (
          (
            await api.serviceDiscovery.getAvailableServicesForCapability(
              props.features,
            )
          ).length > 0
        );
      })(),
    );
  }, []);

  return (
    // <Button startIcon={isAvailable.state !== 'resolved' && <Search />} {...props} disabled={!(isAvailable.state === 'resolved' && isAvailable.value)}>
    //   {
    //     props.children
    //   }
    // </Button>
    <LoadingButton
      {...props}
      loadingPosition="start"
      loading={isAvailable.state !== "resolved"}
      {...(() => {
        let x: any = {};
        if (isAvailable.state === "resolved" && isAvailable.value === false) {
          x.startIcon = <WarningAmber />;
        }
        return x;
      })()}
      disabled={!(isAvailable.state === "resolved" && isAvailable.value)}
    >
      {props.children}
    </LoadingButton>
  );
}

export function RequiresBackendFeatures(props: {
  features: string[];
  children: React.ReactNode;
  loading?: React.ReactNode;
  unavailable?: React.ReactNode;
}) {
  const LoadingElement = props.loading;
  const [isAvailable, setIsAvailable] = usePromise<boolean>();

  useMemo(() => {
    setIsAvailable(
      (async () => {
        const api = await getMicroserviceCoreAPIClient();
        return (
          (
            await api.serviceDiscovery.getAvailableServicesForCapability(
              props.features,
            )
          ).length > 0
        );
      })(),
    );
  }, []);

  return (
    <>
      {(isAvailable.state === "pending" || isAvailable.state === "idle") &&
        LoadingElement}
      {isAvailable.state === "resolved" && (
        <>
          {isAvailable.value && props.children}
          {!isAvailable.value && props.unavailable}
        </>
      )}
    </>
  );
}

export function AvailableMicroservices() {
  const [availableMicroservicesPromise, setAvailableMicroservicesPromise] =
    usePromise<MicroserviceInstance[]>();
  useMemo(async () => {
    const api = await getMicroserviceCoreAPIClient();
    setAvailableMicroservicesPromise(
      (async () => {
        const result = api.serviceDiscovery.getAvailableServices();
        return result;
      })(),
    );
  }, []);

  return (
    <>
      <Masonry>
        {availableMicroservicesPromise.state === "pending" && (
          <React.Fragment key="__placeholder">
            <Card>
              <CardHeader title="Service Instance" subheader={<Skeleton />} />
              <CardContent>
                <Typography variant="body1">
                  <Skeleton />
                </Typography>

                <BorderedSection
                  title={(<Skeleton sx={{ minWidth: "150px" }} />) as any}
                  icon={<Info />}
                >
                  <Masonry>
                    {[0, 1, 2, 3].map((capability) => (
                      <Chip
                        key={capability}
                        label={
                          <Skeleton
                            sx={{
                              minWidth: "50px",
                            }}
                          />
                        }
                      />
                    ))}
                  </Masonry>
                </BorderedSection>
              </CardContent>
            </Card>
          </React.Fragment>
        )}
        {availableMicroservicesPromise.state === "resolved" &&
          availableMicroservicesPromise.value.map((service) => {
            return (
              <React.Fragment key={service.rootURI}>
                <Card>
                  <CardHeader
                    title="Service Instance"
                    subheader={service.implementationID}
                  />
                  <CardContent>
                    <Typography variant="body1">
                      Exposed at{" "}
                      <Link href={service.rootURI}>{service.rootURI}</Link>
                    </Typography>

                    <BorderedSection
                      title="Available Feature Codes"
                      icon={<Info />}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        {service.fulfills.map((capability) => (
                          <Chip
                            key={capability}
                            label={<code>{capability}</code>}
                            sx={{
                              margin: "0.25em",
                            }}
                          />
                        ))}
                      </Box>
                    </BorderedSection>
                  </CardContent>
                </Card>
              </React.Fragment>
            );
          })}
      </Masonry>
    </>
  );
}
