import { getMicroserviceCoreAPIClient } from "../microservice-core";

export async function injectGraphFormatters() {
  const client = await getMicroserviceCoreAPIClient();
  // client.sensorData.graphFormatters['x::glance::24h'] = ((value: number, timestamp: Date, opts: unknown) => {
  //   return new Date(value).toLocaleTimeString('en-US');
  // }) as any;
  client.sensorData.graphFormatters['std::numeric::fixed'] = {
    contextualizer: context => (value: number) => {
      return [(Number(value)).toFixed(context.length as number ?? 2)];
    }
  };
  client.sensorData.graphFormatters['std::units::degreeSymbol'] = {
    contextualizer: context => (value: unknown) => {
      return [`${value}°${context.tail}`];
    }
  };
  client.sensorData.graphFormatters['std::valueManipulate::wrap'] = {
    contextualizer: context => (value: unknown) => {
      return [`${context['head'] ?? ''}${value}${context['tail'] ?? ''}`];
    }
  };
  client.sensorData.graphFormatters['std::date::jsFormatter'] = {
    contextualizer: context => (value: unknown) => {
      const date = new Date(value as string);
      const result = date.toLocaleString(undefined, {
        ...(context as {})
      });
      return [result];
    }
  }
}
