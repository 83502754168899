import { RequiresBackendFeatures } from "@features/microservice-core/components/serviceDiscovery";

import Chart from "react-apexcharts";

type ChartData = {
  options: ApexCharts.ApexOptions,
  series: ApexAxisChartSeries | ApexNonAxisChartSeries
};

export function SensorDataTesting() {
  const config: ChartData = {
    options: {
      chart: {
        type: 'boxPlot',
        toolbar: {
          show: true,
        },
      },
      xaxis: {
        labels: {
          show: false
        }
      }
    },
    series: [{
      name: "Data",
      type: "boxPlot",
      data: [
        {
          "x": "WeatherRack2: Wednesday, March 13, 2024",
          "y": [
            5.33,
            7.445,
            10.28,
            13.915,
            15.28
          ]
        },
        {
          "x": "WeatherRack2: Wednesday, March 20, 2024",
          "y": [
            -3.28,
            2.17,
            5.44,
            8.345,
            15.56
          ]
        },
        {
          "x": "WeatherRack2: Wednesday, March 27, 2024",
          "y": [
            5.61,
            8.17,
            10.17,
            12.33,
            20.28
          ]
        },
        {
          "x": "WeatherRack2: Wednesday, April 3, 2024",
          "y": [
            2.5,
            5.56,
            8.78,
            16.22,
            26.28
          ]
        },
        {
          "x": "WeatherRack2: Wednesday, April 10, 2024",
          "y": [
            7.22,
            13.56,
            16.83,
            21.015,
            30.06
          ]
        },
        {
          "x": "WeatherRack2: Wednesday, April 17, 2024",
          "y": [
            1.78,
            10.61,
            14,
            17.78,
            54.56
          ]
        },
        {
          "x": "WeatherRack2: Wednesday, April 24, 2024",
          "y": [
            5.28,
            10.5,
            15.83,
            22.015,
            33.17
          ]
        },
        {
          "x": "WeatherRack2: Wednesday, May 1, 2024",
          "y": [
            9.78,
            15.5,
            18.28,
            22.67,
            33.06
          ]
        },
        {
          "x": "WeatherRack2: Wednesday, May 8, 2024",
          "y": [
            5.67,
            13.11,
            14.89,
            16.72,
            25.89
          ]
        },
        {
          "x": "WeatherRack2: Wednesday, May 15, 2024",
          "y": [
            11.33,
            15.28,
            17.78,
            22.14,
            32.56
          ]
        },
        {
          "x": "WeatherRack2: Wednesday, May 22, 2024",
          "y": [
            11.33,
            18.11,
            20.83,
            24.78,
            31.33
          ]
        },
        {
          "x": "WeatherRack2: Wednesday, May 29, 2024",
          "y": [
            9.56,
            16.56,
            19.83,
            23.83,
            30.78
          ]
        },
        {
          "x": "WeatherRack2: Wednesday, June 5, 2024",
          "y": [
            11.06,
            18,
            21,
            24.28,
            31.44
          ]
        },
        {
          "x": "WeatherRack2: Wednesday, June 12, 2024",
          "y": [
            12.83,
            19.67,
            24.78,
            29.06,
            38
          ]
        },
        {
          "x": "WeatherRack2: Wednesday, June 19, 2024",
          "y": [
            14.11,
            22.94,
            26.915,
            31.5,
            38.33
          ]
        },
        {
          "x": "WeatherRack2: Wednesday, June 26, 2024",
          "y": [
            19.33,
            20.11,
            20.89,
            27.14,
            31.33
          ]
        }
      ]
    },]
  };

  return (
    <>
      <RequiresBackendFeatures
        features={["sensor-data"]}
        loading={<p>Locating feature provider...</p>}
        unavailable={<p>Unable to locate a feature provider.</p>}
      >
        <Chart {...config} />
      </RequiresBackendFeatures>
    </>
  );
}
