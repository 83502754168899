import { urlJoin } from "../../workarounds/url-join";
import { getMicroserviceCoreAPIClient } from "..";
import { createTypedEventEmitter } from '../../eventemitter';
import { Permission, UserInfo } from "../../types/authentication";

type User = any; // Stand-in until API stabilization

type AuthState = {
  state: 'authenticated',
  user: User
} | {
  state: 'unauthenticated'
} | {
  state: 'loading'
};


let AUTH_TOKEN: string | undefined = undefined;

(() => {
  const fetch = globalThis.fetch;

  function getCookie(name: string) {
    if (typeof globalThis.window === 'undefined') {
      return AUTH_TOKEN; // NodeJS, no document, no cookies
    }
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop()!.split(';').shift();
  }

  globalThis.fetch = (url, options) => {
    options = options || {};
    options.credentials = 'include';
    const headers: Headers = new Headers(options.headers);
    options.headers = headers;
    {
      const token = getCookie('auth-token');
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
    }
    console.log(options.headers);
    // options.headers['auth-token'] = getCookieValue('auth-token');
    return fetch(url, options);
  };
})();

const getMicroservice = async () => {
  const client = await getMicroserviceCoreAPIClient();
  const microservices = await client.serviceDiscovery.getAvailableServicesForCapability(['authentication']);
  if (microservices.length < 1) {
    throw new Error(`No service available to handle capability 'authentication'`);
  }
  return microservices[0];
};



export async function authenticationServicePlugin() {
  const eventEmitter = createTypedEventEmitter<{
    onStateChange: AuthState,
    userInfoUpdate: UserInfo | undefined
  }>();

  const isFeatureAvailable = async () => {
    const client = await getMicroserviceCoreAPIClient();
    const microservices = await client.serviceDiscovery.getAvailableServicesForCapability(['authentication']);
    if (microservices.length < 1) {
      return false;
    }
    return true;
  }

  const login = async (username: string, password: string) => {
    eventEmitter.emit('onStateChange', {
      state: 'loading'
    });
    const microservice = await getMicroservice();
    const loginURL = urlJoin(microservice.rootURI, "login");
    const init: RequestInit = {
      method: 'POST',
      body: JSON.stringify({
        username,
        password
      }),
      headers: {
        'content-type': 'application/json'
      },
      credentials: 'include',
    };
    try {
      const response = await fetch(loginURL, init);
      if (response.status === 403) {
        eventEmitter.emit('onStateChange', {
          state: 'authenticated',
          user: await getUserInfo()
        });
        return;
      }
      if (!response.ok) {
        throw new Error(`Failed to login`);
      }

      AUTH_TOKEN = await response.text();

      eventEmitter.emit('onStateChange', {
        state: 'authenticated',
        user: await getUserInfo()
      });

    } catch (e) {
      eventEmitter.emit('onStateChange', {
        state: 'unauthenticated'
      });
      throw e;
    }
  };

  const getUserInfo = async () => {
    const microservice = await getMicroservice();
    const targetURL = urlJoin(microservice.rootURI, "user");
    const fetchResult = await fetch(targetURL);
    if (!fetchResult.ok) {
      eventEmitter.emit('userInfoUpdate', undefined);
      const errorBody = await fetchResult.json();
      throw new Error(errorBody.message);
    }
    const user: UserInfo = await fetchResult.json();
    eventEmitter.emit('userInfoUpdate', user);
    return user;
  }

  const isLoggedIn = async () => {
    try {
      const user = await getUserInfo();
      eventEmitter.emit('onStateChange', {
        state: 'authenticated',
        user: user
      });
      return true;
    }
    catch {
      return false;
    }
  };

  let userPermissionsStore: any | undefined = undefined;

  eventEmitter.on('onStateChange', () => userPermissionsStore = undefined);

  const getUserPermissions = async (): Promise<Permission[]> => {
    if (userPermissionsStore) return userPermissionsStore;
    const microservice = await getMicroservice();
    const targetURL = urlJoin(microservice.rootURI, "permissions");
    const response = await fetch(targetURL);
    const responseBody: Permission[] = await response.json();
    userPermissionsStore = responseBody;
    return responseBody;
  };

  const getEventEmitter = () => eventEmitter;

  async function authenticationServicePluginMicroserviceAPI() {
    const getUserInformation = async (token: string) => {
      const microservice = await getMicroservice();
      const baseTargetURL = urlJoin(microservice.rootURI, "private", "getUserInformation");
      const fullTargetURL = baseTargetURL + `?token=${token}`;
      const response = await fetch(fullTargetURL);
      if (!response.ok) {
        const errorBody = await response.json();
        throw new Error(errorBody.message);
      }
      const responseBody: UserInfo = await response.json();
      return responseBody;
    };

    const getUserPermissionsRestricted = async (token: string) => {
      const microservice = await getMicroservice();
      const baseTargetURL = urlJoin(microservice.rootURI, "private", "getUserPermissions");
      const fullTargetURL = baseTargetURL + `?token=${token}`;
      const response = await fetch(fullTargetURL);
      if (!response.ok) {
        try {
          console.log(await response.text());
        } catch { }
        throw new Error(`Unknown error: Unable to fetch user permissions`);
      }
      const responseBody: Permission[] = await response.json();
      return responseBody;
    };

    return {
      getUserInformation,
      getUserPermissions: getUserPermissionsRestricted
    }
  }

  setTimeout(isLoggedIn, 1500); // Immediately check authentication status

  return {
    isFeatureAvailable,
    login,
    isLoggedIn,
    getUserInfo,
    getUserPermissions,
    getEventEmitter,
    microserviceAPI: await authenticationServicePluginMicroserviceAPI()
  }
}
