import { useEffect, useState } from "react";
import React from "react";

export type PromiseHookValue<T> =
  | {
    state: "idle";
  }
  | {
    state: "pending";
    startedAt: Date;
  }
  | {
    state: "resolved";
    value: T;
    startedAt: Date;
    finishedAt: Date;
  }
  | {
    state: "rejected";
    error: any;
    startedAt: Date;
    finishedAt: Date;
  };

export type PromiseHookSetter<T> = (newPromise: Promise<T>) => Promise<T>;
export type PromiseHookResetter = () => void;
export type PromiseHook<T> = [
  PromiseHookValue<T>,
  PromiseHookSetter<T>,
  PromiseHookResetter,
];

export type PromiseHookDefaultValue<T> = () => Promise<T>;

export function usePromise<T>(
  defaultValue?: PromiseHookDefaultValue<T>,
): PromiseHook<T> {
  const [value, setValue] = useState<PromiseHookValue<T>>({
    state: "idle",
  });

  const internalValueSetterRef = React.useRef<typeof setValue>(setValue);

  useEffect(() => {
    internalValueSetterRef.current = setValue;

    return () => {
      internalValueSetterRef.current = () => {
        console.warn(
          "PromiseHook: internalValueSetterRef.current is called after unmounting",
        );
      };
    };
  }, []);

  const setter: PromiseHookSetter<T> = async (newPromise) => {
    const startedAt = new Date();
    internalValueSetterRef.current({
      state: "pending",
      startedAt,
    });

    try {
      const value = await newPromise;
      internalValueSetterRef.current({
        state: "resolved",
        startedAt,
        finishedAt: new Date(),
        value,
      });
      return value;
    } catch (error) {
      internalValueSetterRef.current({
        state: "rejected",
        error,
        startedAt,
        finishedAt: new Date(),
      });
      throw error;
    }
  };

  const resetter: PromiseHookResetter = () => {
    internalValueSetterRef.current({
      state: "idle",
    });
  };

  useEffect(() => {
    if (defaultValue !== undefined) {
      setter(defaultValue());
    }
  }, []);

  return [value, setter, resetter];
}

export type PromiseHookGroup = Map<string, PromiseHookValue<any>>;

export function usePromiseGroup() {
  const map = new Map<string, PromiseHookValue<any>>();

  const use = <T>(key: string, target: PromiseHookValue<T>) => {
    map.set(key, target);
  };

  const getGroup = (): PromiseHookGroup => map;

  return { use, getGroup };
}
