export const MSCCommon = {
  nestJS: {
    getResponseOrThrowError: async (response: Response, errorLeader?: string) => {
      if (response.ok) {
        return await response.json();
      }
      else {
        const errorBody = await response.json();
        const messageStart = `${errorLeader ?? ""}${errorLeader ? ": " : ""}`;
        throw new Error(`${messageStart}${errorBody.message}`);
      }
    }
  }
}
